.clearfix:after {
  clear: both;
  content: "";
  display: table;
}
.grid-01 {
  width: 8.333333%;
}
.grid-01,
.grid-02 {
  box-sizing: border-box;
  float: left;
  min-height: 1px;
}
.grid-02 {
  width: 16.666667%;
}
.grid-03 {
  width: 25%;
}
.grid-03,
.grid-04a {
  box-sizing: border-box;
  float: left;
  min-height: 1px;
}
.grid-04a {
  width: 27.5%;
}
.grid-04 {
  width: 33.333333%;
}
.grid-04,
.grid-05 {
  box-sizing: border-box;
  float: left;
  min-height: 1px;
}
.grid-05 {
  width: 41.666667%;
}
.grid-06 {
  width: 50%;
}
.grid-06,
.grid-07 {
  box-sizing: border-box;
  float: left;
  min-height: 1px;
}
.grid-07 {
  width: 58.333333%;
}
.grid-08 {
  width: 66.666667%;
}
.grid-08,
.grid-08a {
  box-sizing: border-box;
  float: left;
  min-height: 1px;
}
.grid-08a {
  width: 72.5%;
}
.grid-09 {
  width: 75%;
}
.grid-09,
.grid-10 {
  box-sizing: border-box;
  float: left;
  min-height: 1px;
}
.grid-10 {
  width: 83.333333%;
}
.grid-11 {
  float: left;
  width: 91.666667%;
}
.grid-11,
.grid-12 {
  box-sizing: border-box;
  min-height: 1px;
}
.grid-12 {
  float: none;
  width: auto;
}
.grid-12:after {
  clear: both;
  content: "";
  display: table;
}
.current-identifier {
  display: inline-flex;
  max-width: 90%;
}
.current-identifier a:first-of-type {
  margin: 0;
  padding-left: 11px;
  padding-right: 11px;
}
.sheet__section > .container-current-identifier {
  padding-bottom: 14px;
  padding-top: 14px;
}
.sheet__section > .container-current-identifier:last-child {
  padding-bottom: 0;
  padding-top: 14px;
}
.sheet__section > .container-current-identifier:first-child {
  padding-bottom: 14px;
  padding-top: 0;
}
.button-current-identifier {
  max-width: 100%;
}
.button-current-identifier span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media only screen and (min-width: 667px) and (max-width: 1184px) {
  .page-section--short .page-section__block {
    padding-right: 0;
  }
}
@media only screen and (min-width: 1185px) {
  .page-section--short .page-section__block {
    padding-right: 0;
  }
}
.no-spinners {
  -moz-appearance: textfield;
}
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.password-unveil:before {
  content: "";
}
.password-unveil-reverse:before,
.password-unveil:before {
  cursor: pointer;
  font-family: exos-icon-font;
  font-style: normal;
  font-weight: 400 !important;
  vertical-align: top;
}
.password-unveil-reverse:before {
  content: "";
}
